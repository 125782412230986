

jQuery(function() {
	$('.header-nav-mobile-icon').click(function() {
		$('.header-nav-mobile').toggleClass('open');
	});
	$('.block-faqs-faq-question').click(function() {
		$(this).next('.block-faqs-faq-answer').toggleClass('open');
	});

	// check if .header-banner exist, if it does change .header-nav-mobile top to 67px
	var hasBanner = $( document ).find( '.header-banner' ).length;
	if(hasBanner == 1){
		$('.header-nav-mobile-icon').css('top', '87px');
		$('.header-nav-mobile').css('top', '157px');
	}

	console.log(hasBanner);	
	$('.product-carousel').slick({
		infinite:true,
		slidesToShow:5,
		slidesToScroll:5,
		responsive: [
		    {
		      breakpoint: 900,
		      settings: {
		        slidesToShow: 3,
		        slidesToScroll: 3
		      }
		    },
		    {
		      breakpoint: 600,
		      settings: {
		        slidesToShow: 2,
		        slidesToScroll: 2
		      }
		    },
		    {
		      breakpoint: 480,
		      settings: {
		        slidesToShow: 1,
		        slidesToScroll: 1
		      }
		    }
		    // You can unslick at a given breakpoint now by adding:
		    // settings: "unslick"
		    // instead of a settings object
		  ]
	});

	$('.sweepstakes-slider').slick({
		infinite:true,
		slidesToShow:3,
		slidesToScroll:3,
		responsive: [
		    {
		      breakpoint: 900,
		      settings: {
		        slidesToShow: 3,
		        slidesToScroll: 3
		      }
		    },
		    {
		      breakpoint: 600,
		      settings: {
		        slidesToShow: 2,
		        slidesToScroll: 2
		      }
		    },
		    {
		      breakpoint: 480,
		      settings: {
		        slidesToShow: 1,
		        slidesToScroll: 1
		      }
		    }
		    // You can unslick at a given breakpoint now by adding:
		    // settings: "unslick"
		    // instead of a settings object
		  ]
	});
});

